import React from 'react';
import { Spin } from 'antd';
import classnames from 'classnames';
import { createRoot } from 'react-dom/client';
import threadSleep from '@utils/threadSleep';
import { usePrevious } from '@subApps/common/hooks';
import Wrapper from './styled';

const getIframeSrc = () => {
  const { href, pathname } = window.location;
  // For Audience Insights
  if (pathname.startsWith('/app/pulse/audience_insights')) {
    return href.replace(/\/app\/pulse\/audience_insights/, '/content_insights');
  }
  // For Pulse 4
  if (pathname.startsWith('/app/pulse')) {
    return href.replace(/\/app\/pulse\//, '/');
  }
  if (['/app/precision', '/app/app_switch'].some(p => pathname.startsWith(p))) {
    return href.replace(/\/app\//, '/');
  }
  return href;
};

const App = () => {
  const iframeRef = React.useRef<HTMLIFrameElement | null>(null);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [prevSrc, setPrevSrc] = React.useState<string>('');
  const [shouldLogin, setShouldLogin] = React.useState<boolean>(false);
  const renderer = React.useState<object | null>(null)[1];

  // Get URL information
  const { pathname, search } = window.location;
  const iframeSrc = getIframeSrc();
  const previousSrc = usePrevious(iframeSrc);
  let actualUrl = iframeSrc;
  if (iframeSrc === previousSrc) {
    if (loading) {
      actualUrl = (iframeSrc as string).includes('?')
        ? `${iframeSrc}&_uuid=${new Date().getTime()}`
        : `${iframeSrc}?_uuid=${new Date().getTime()}`;
    } else {
      actualUrl = prevSrc;
    }
  }
  const hasQuery = actualUrl.includes('?');
  const handleLoad = () => {
    setLoading(false);
    setPrevSrc(actualUrl);
  };

  const handleBeforeunload = async () => {
    // Make sure the location has been changed
    await threadSleep(0);
    const iframeNode = iframeRef.current;
    if (iframeNode === null || !iframeNode.contentWindow) {
      return;
    }
    const { href: iframeHref } = iframeNode.contentWindow.location;
    // if the iframe logouts, the parent window should navigate to login page.
    if (/\/login/.test(iframeHref)) {
      setShouldLogin(true);
    }
  };

  // Add the unload event when the iframe location has been changed
  React.useEffect(() => {
    const iframe = iframeRef.current;
    if (iframe === null || !iframe.contentWindow) {
      return;
    }
    iframe.contentWindow.addEventListener('unload', handleBeforeunload);
    return () => {
      if (iframe === null || !iframe.contentWindow) {
        return;
      }
      iframe.contentWindow.removeEventListener('unload', handleBeforeunload);
    };
  }, [iframeRef.current]);

  // Because of the security problem of iframe, it needs to be replaced outside the iframe.
  React.useEffect(() => {
    if (!shouldLogin) {
      return;
    }
    const url = `/login?next=${pathname}${search}`;
    window.location.replace(url);
  }, [shouldLogin]);

  const handlePopstate = React.useCallback(() => {
    setLoading(true);
    renderer({});
  }, [renderer]);

  React.useEffect(() => {
    window.addEventListener('popstate', handlePopstate);
    window.addEventListener('pushState', handlePopstate);
    window.addEventListener('replaceState', handlePopstate);
    return () => {
      window.removeEventListener('popstate', handlePopstate);
      window.removeEventListener('pushState', handlePopstate);
      window.removeEventListener('replaceState', handlePopstate);
    };
  }, [handlePopstate]);

  if (/\/app\/pulse\/?$/.test(pathname)) {
    window.location.href = '/app';
    return null;
  }

  return (
    <Wrapper className="pulse_4_loader">
      <div
        className={classnames('conviva-loading-wrapper', {
          loading,
        })}
      >
        <Spin wrapperClassName="loading-wrapper" tip="Loading..." />
      </div>
      <iframe
        ref={iframeRef}
        className={classnames({ 'conviva-iframe-loading': loading })}
        title="Pulse 4"
        onLoad={handleLoad}
        onError={handleLoad}
        sandbox="allow-same-origin allow-scripts allow-popups allow-forms allow-top-navigation allow-downloads"
        src={`${actualUrl}${hasQuery ? '&isMicro=true' : '?isMicro=true'}`}
      />
    </Wrapper>
  );
};

let wrapper: null | HTMLDivElement = null;
let root;

const render = props => {
  const { container } = props;
  if (wrapper) {
    container.removeChild(container);
  }
  wrapper = document.createElement('div');
  wrapper.style.width = '100%';
  wrapper.style.height = '100%';
  container.appendChild(wrapper);

  root = createRoot(wrapper);
  root.render(<App {...props} />);
};

export async function bootstrap() {
  // todo
}

export async function mount(props) {
  props.setGlobalState({ theme: 'light' });
  render(props);
}

export async function unmount() {
  if (wrapper) {
    wrapper = null;
  }
  if (root) {
    root.unmount();
  }
}

export async function update() {
  // todo
}

if (!window.__POWERED_BY_QIANKUN__) {
  render({});
}
